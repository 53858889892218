






































































  import {Component, Vue} from "vue-property-decorator";
  import nodeDropFun from './util/nodeDrop'
	import _ from 'lodash'

  @Component({})
  export default class knowledge extends Vue {

    private subjectArr: any = [];
    private options: any = [{}, {}, {}];
    private subjectName = '';
    private knowledgeData = [];
    private formLabelWidth = '120px'
    private dialogFormVisible = false
	  private currentNode:any = {}
	  private knowledgeName = ''
	  private currentId = ''
	  private defaultExpandNodes: any = []

	private nodeExpend(nodes, node, self) {
		let id: any = null
		id = node.data.id
		this.defaultExpandNodes.push(id)
	}
	private nodeCollapse(nodes, node, self) {
		let id: any = null
		id = node.data.id
		this.defaultExpandNodes = this.defaultExpandNodes.filter(i => i !== id)
	}
	private nodeDrop(draggingNode, dropNode, dropType, ev) {
      this.axios.post('/tiku/knowledge/drag', nodeDropFun(draggingNode, dropNode, dropType, ev), { headers: {'Content-Type':'application/json'}}).then((res: any) => {
        this.getKnowledge(this.subjectArr[1])
        if(res.result) {
          this.$message({message: '保存成功', type: 'success' })
          this.dialogFormVisible = false;
        }else{
          this.$message({message: res.msg, type: 'error' });
        }
      });
    }
    private async getSubject() {
      const res: any = await this.axios.post('/system/dictionary/querySubject');
      if (res.result) {
        Object.keys(res.json).forEach((key, index) => {
          let subjectParName = ''
          switch (key) {
            case 'high':
              subjectParName = '高中'
              break;
            case 'middle':
              subjectParName = '初中'
              break;
            case 'primary':
              subjectParName = '小学'
              break;
            default:
              subjectParName = '小学'
              break;
          }
          this.$set(this.options[index], 'value', key)
          this.$set(this.options[index], 'label', subjectParName)
          this.$set(this.options[index], 'children', res.json[key])
        });
        this.options.forEach((item: any, index: number) => {
          item.children.forEach((key: any) => {
            this.$set(key, 'value', key.code)
            this.$set(key, 'label', key.name)
          });
        });
		this.options = this.options.filter(i => i.children && i.children.length !== 0)
        this.subjectArr[0] = this.options[0].value;
        this.subjectArr[1] = this.options[0].children[0].value;
        setTimeout(() => {
          this.getKnowledge(this.subjectArr[1]);
        })
      }
    };

    private append(data) {
      this.dialogFormVisible = true;
      this.currentNode = data
    };

    private edit(data) {
      this.dialogFormVisible = true;
      this.currentNode = data;
      this.knowledgeName = data.name;
      this.currentId = data.id;
    };

    private addOrUpdateKnowledge() {
			this.saveChapterInfo(this.currentId, this.knowledgeName, this.currentNode.id, this.subjectArr[1])
    };

    private saveChapterInfo(id = '', knowledgeName, parentId, subject) {
      this.axios.post('/tiku/knowledge/saveOrUpdate', {id, knowledgeName, parentId, subject}).then((res: any) => {
        this.dialogFormVisible = false;
        this.getKnowledge(this.subjectArr[1])
      });
    }

    private async remove(node, {id}) {
      let confirm = await this.$confirm('此操作将永久删除该知识点, 是否继续?', '提示', { type: 'warning' });
      if (confirm) {
        const res: any = await this.axios.post('/tiku/knowledge/deleteById', {id});
        res.result && this.$message.success('删除知识点成功~！') && this.getKnowledge(this.subjectArr[1])
      };
    }

    private getKnowledge(subjectId) {
      setTimeout(() => {
        const cascaderREF: any = this.$refs['cascader'];
        this.subjectName = cascaderREF.presentText.split('/')[1];
        this.axios.post('/tiku/knowledge/queryTree', {subjectId}).then((res: any) => {
          this.knowledgeData = res.json;
        })
      })
    };

    private down(data) {
      this.moveUpOrDown(data.id, -1);
    };

    private up(data) {
      this.moveUpOrDown(data.id, 1);
    };

    private moveUpOrDown(knowledgeId, moveType) {
      this.axios.post('/tiku/knowledge/moveUpOrDownKnowledge', {knowledgeId, moveType}).then((res: any) => {
        this.getKnowledge(this.subjectArr[1])
      })
    };

    private subjectChange() {
      this.getKnowledge(this.subjectArr[1]);
    }

    private created() {
      this.getSubject()
    };

    private mounted() {
      let dom: any = document.getElementsByClassName('container')[0];
      dom.style.background = '#F4F5F9';
      dom.style.boxShadow = 'none';
      dom.style.border = 'none';
      dom.style.height = "100%";


			this.axios.get<null, any>('/xkw/api/querySubject').then(res => {
				this.subjectOptions[0] = { code: 0, name: '小学', children: res.json.primary };
				this.subjectOptions[1] = { code: 1, name: '初中', children: res.json.middle };
				this.subjectOptions[2] = { code: 2, name: '高中', children: res.json.high };
			});
    }

    private destroyed() {
      let dom: any = document.getElementsByClassName('container')[0];
      dom.style.background = '';
      dom.style.boxShadow = '';
      dom.style.border = '';
      dom.style.height = ""
    }

		private subjectOptions: any[] = [];
		private showBatchAddKnowledge = false;
		private knowledgeOtherData: any[] = [];
		private getNewKnowledgeLoading = false;
		private saveBatchLoading = false;
		private batchAddKnowledge() {
			this.showBatchAddKnowledge = true;
			let ref = this.$refs.knowledgeAddRef as any;
			ref && ref.setCheckedKeys([]);
		}
		private async getKnowledgeData(val) {
			this.getNewKnowledgeLoading = true;
			const res = await this.axios.get<null, any>(`/xkw/api/queryKnowledge?subjectCode=${ val[1] }`);
			this.knowledgeOtherData = res.json;
			this.getNewKnowledgeLoading = false;
		}
		private async batchEnter() {
			this.saveBatchLoading = true;
			let keys = (this.$refs.knowledgeAddRef as any).getCheckedNodes(false, true).map(i => i.id);
			let data = getData(_.cloneDeep(this.knowledgeOtherData), keys, this.subjectArr[1]);
			const res = await this.axios.post<null, any>('/tiku/knowledge/batchInsertKnowledge', data, { headers: { 'Content-Type': 'application/json' } });
			this.saveBatchLoading = false;
			if (res.result) {
				this.getKnowledge(this.subjectArr[1]);
				this.showBatchAddKnowledge = false;
			}
		}
  }



const getData = (list, keys, subjectId) => {
	let data = list.filter(i => keys.includes(i.id));
	data.map(item => {
		item.subjectId = subjectId;
		if (item.childs) {
			item.childs = getData(item.childs, keys, subjectId)
		}
	})
	return data;
}
