export default (draggingNode, dropNode, dropType, ev) => {
  let data: any = []
  if (!dropNode.data.parentId || dropNode.data.parentId == '0') {
    if (dropType === 'inner') {
      draggingNode.data.parentId = dropNode.data.id
      draggingNode.data.parentPath = dropNode.data.path
      draggingNode.data.parentPathName = dropNode.data.pathName

      draggingNode.data.path = dropNode.data.path + draggingNode.data.id
      draggingNode.data.pathName = dropNode.data.pathName + draggingNode.data.name
      data = dropNode.data.childs
    } else if(dropType === 'before') {
      draggingNode.data.parentId = null;
      draggingNode.data.parentPath = null;
      draggingNode.data.parentPathName = null;
      draggingNode.data.path = `-${draggingNode.data.id}-`;
      draggingNode.data.pathName = `-${draggingNode.data.name}-`;

      data = dropNode.parent.data
    } else {
      draggingNode.data.parentId = ''
      draggingNode.data.parentPath = dropNode.data.path
      draggingNode.data.parentPathName = dropNode.data.pathName

      draggingNode.data.path = dropNode.data.path + draggingNode.data.id
      draggingNode.data.pathName = dropNode.data.pathName + draggingNode.data.name

      data = dropNode.parent.data
    }
  } else {
    if (dropType === 'inner') {
      draggingNode.data.parentId = dropNode.data.id
      draggingNode.data.parentPath = dropNode.data.path
      draggingNode.data.parentPathName = dropNode.data.pathName

      draggingNode.data.path = dropNode.data.path + draggingNode.data.id
      draggingNode.data.pathName = dropNode.data.pathName + draggingNode.data.name

      data = dropNode.data.childs
    } else {
      draggingNode.data.parentId = dropNode.parent.data.id
      draggingNode.data.parentPath = dropNode.data.path
      draggingNode.data.parentPathName = dropNode.data.pathName

      draggingNode.data.path = dropNode.data.path + draggingNode.data.id
      draggingNode.data.pathName = dropNode.data.pathName + draggingNode.data.name

      data = dropNode.parent.data.childs
    }
  }
  return data
}
